<!--
 * @Description: 头部注释
 * @Author: v_binqin
 * @Date: 2022-11-27 01:19:17
 * @LastEditors: v_binqin
 * @LastEditTime: 2022-11-27 19:30:33
-->
<template>
  <div class="box">
   
    <div class="content">
      <p class="content-title">燃气设备</p>

      <div class="div-item">
        <van-row class="cardItem">
          <van-col span="7">
            <van-image class="img" width="100" height="100" :src="smokeurl" @click="goDeviceList(1)"/>
          </van-col>
          <van-col span="17">
            <p>安装总台数:{{ ranqiInfo.allCount }}台</p>
            <p>在线设备数:{{ ranqiInfo.onlineCount }}台</p>
            <p>离线设备数:{{ ranqiInfo.offlineCount }}台</p>
          </van-col>
        </van-row>
        <van-button type="primary" class="check-button" @click="checkDevice(1)">
          检查设备
        </van-button>
      </div>

      <p class="content-title">烟感设备</p>

      <div class="div-item">
        <van-row class="cardItem">
          <van-col span="7">
            <van-image class="img" width="100" height="100" :src="yanganurl" @click="goDeviceList(2)" />
          </van-col>
          <van-col span="17">
            <p>安装总台数:{{ yanganInfo.allCount }}台</p>
            <p>在线设备数:{{ yanganInfo.onlineCount }}台</p>
            <p>离线设备数:{{ yanganInfo.offlineCount }}台</p>
          </van-col>
        </van-row>
        <van-button type="primary" class="check-button" @click="checkDevice(2)">
          检查设备
        </van-button>
      </div>
      <p class="content-title">空开设备</p>

      <div class="div-item">
        <van-row class="cardItem">
          <van-col span="7">
            <van-image class="img" width="100" height="100" :src="kongkaiurl" @click="goDeviceList(3)"/>
          </van-col>
          <van-col span="17">
            <p>安装总台数:{{ weiduanInfo.allCount }}台</p>
            <p>在线设备数:{{ weiduanInfo.onlineCount }}台</p>
            <p>离线设备数:{{ weiduanInfo.offlineCount }}台</p>
          </van-col>
        </van-row>
        <van-button type="primary" class="check-button" @click="checkDevice(3)">
          检查设备
        </van-button>
      </div>
      <a href="https://mp.weixin.qq.com/s/dgIulu1btbCgD0rbOEZ1Ug"><p style="font-size: 20px">燃气安装规范说明</p></a>
      <a href="https://mp.weixin.qq.com/s/ZBjwhnYcUdM28go9JH4QCg"><p style="font-size: 20px">烟感安装规范说明</p></a>
      <a href="https://mp.weixin.qq.com/s/9PyMy88oYqKSz8qyOUfE0Q"><p style="font-size: 20px">空开安装规范说明</p></a>
    </div>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="设备类型"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
     <van-button type="info" class="bottom-button" @click="showPicker = true">
      添加设备
    </van-button>
  </div>
</template>
<script>
import { Card } from "vant";
export default {
  data() {
    return {
      smokeurl:
        require('../assets/ranqi.jpg'),
      yanganurl:
        require('../assets/yangan.jpg'),
      kongkaiurl:
        require('../assets/kongkai.jpg'),
      showPicker: false,
      columns: ["微断", "燃气", "烟感"],
      weiduanInfo: {
        allCount: 0,
        onlineCount: 0,
        offlineCount: 0,
      },
      yanganInfo: {
        allCount: 0,
        onlineCount: 0,
        offlineCount: 0,
      },
      ranqiInfo: {
        allCount: 0,
        onlineCount: 0,
        offlineCount: 0,
      },
      list: [],
    };
  },
  created() {},
  methods: {
    onCancel(){
      this.showPicker = false;
    },
    onConfirm(value, index) {
      this.showPicker = false;
      switch (index) {
        // case 0:
        //   this.$router.push("/microbreak");
        //   break;

        default:
          let type = -1;
          if (index == 0) {
            type = 5;
          } else if (index == 1) {
            type = 3;
          } else {
            type = 4;
          }
          this.scanCode(type);
          break;
      }
    },
    goDeviceList(type){
      this.$router.push({path:'/deviceList',query:{type:type}})
    },
    is_weixn() {
      return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
    },
    checkDevice(type) {
      this.getOverView();
    },
    scanCode(type) {
      if (!this.is_weixn()) {
        alert("请用微信打开页面，扫码功能仅支持微信页面");
        return;
      }
      let href = location.origin + "/#/addEquipment?type=" + type;
      location.href = `https://www.996315.com/api/scan?redirect_uri=${encodeURIComponent(
        href
      )}`;
    },
    getOverView() {
      this.http
        .get("/mp/sg/overview", {
          params: {
            companyId: localStorage.getItem("companyId"),
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            if (res.data.data) {
              let list = res.data.data;
              this.ranqiInfo.allCount = list[1].deviceTotalCount;
              this.ranqiInfo.onlineCount = list[1].onlineDeviceCount;
              this.ranqiInfo.offlineCount = list[1].offlineDeviceCount;

              this.yanganInfo.allCount = list[2].deviceTotalCount;
              this.yanganInfo.onlineCount = list[2].onlineDeviceCount;
              this.yanganInfo.offlineCount = list[2].offlineDeviceCount;

              this.weiduanInfo.allCount = list[0].deviceTotalCount;
              this.weiduanInfo.onlineCount = list[0].onlineDeviceCount;
              this.weiduanInfo.offlineCount = list[0].offlineDeviceCount;
            }
          }
        });
    },
    getUserInfo() {
      this.http.get('/user/me').then(({ data }) => {
        if (data.code == 200) {
          localStorage.setItem('companyId', data.data.companyId)
          localStorage.setItem('phone', data.data.username)
          localStorage.setItem('id', data.data.id)
          this.getOverView()
        }
      })
    },
  },
  mounted() {
    let code = this.$route.query.qrresult;
    this.getUserInfo();
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  position: relative;
  .bottom-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 1px;
    left: 12px;
    .content-title {
      font-size: 28px;
    }
    .cardItem {
      background-color: #ffffff;
      width: 100%;
      height: 200px;
      .img {
        margin-top: 16px;
        margin-left: 10px;
      }
    }
    .div-item {
      position: relative;
      .check-button {
        position: absolute;
        right: 20px;
        bottom: 12px;
      }
    }
  }
}
</style>
